import CheckIcon from '@mui/icons-material/Check';
import { Grid, Typography } from '@mui/material';
import React from 'react';

export interface AppointmentSuccessProps {
	message: JSX.Element | string;
}
export const AppointmentSuccess: React.FC<AppointmentSuccessProps> = ({ message }) => {
	
	return (
		<Grid
			container
			justifyContent="center"
			alignItems="center"
			sx={{
				width: '100%',
				height: '100vh',
				padding: '10px',
			}}>
			<Grid container justifyContent="center" alignItems="center" sx={{ flexDirection: 'column' }}>
				<div
					style={{
						width: '250px',
						height: '250px',
						borderRadius: '50%',
						backgroundColor: '#d2f8d2',
						justifyContent: 'center',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
					}}>
					<CheckIcon style={{ color: '#fff', fontSize: '96px', fontWeight: 'bold' }} />
				</div>
				<Typography variant="body1" component="p" sx={{ color: '#00509E', textAlign: 'center' }}>
					{message}
				</Typography>
			</Grid>
		</Grid>
	);
};

export default AppointmentSuccess;
