import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./pages/RouteError";
import AppointmentRequest from "./pages/appointment-request/AppointmentRequest";
import { QueryClient, QueryClientProvider } from "react-query";
import AppointmentSuccess from "./pages/appointment-request/sinem/AppointmentSuccess";

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
  },
  {
    path: "/appointment",
    element: <AppointmentRequest />,
  },
  {
    path: "/appointment/success",
    element: (
      <AppointmentSuccess message="Demande vérifiée avec succès, veuillez attendre la confirmation email (ceci peut prendre jusqu'à 2 minutes)." />
    ),
  },
]);
const queryClient = new QueryClient();
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
