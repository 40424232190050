import { ExamDto } from "../interfaces/api-interfaces";
import { APiBaseService } from "./api-base.service";

export class ExamService extends APiBaseService {
  constructor() {
    super();
    this.baseUrl = `${process.env.REACT_APP_API_URL}/exam`;
  }
  findExams(
    siteId?: number,
    examTypeId?: number
  ): Promise<{ totalCounts: number; datas: ExamDto[] }> {
    let url = `${this.baseUrl}?`;
    if (examTypeId) {
      url += `&examTypes[]=${examTypeId}`;
    }
    if (siteId) {
      url += `&siteIds[]=${siteId}`;
    }
    return this.get<{ totalCounts: number; datas: ExamDto[] }>(url);
  }

  findExamTypes(siteId: number): Promise<ExamDto[]> {
    let url = this.baseUrl;
    return this.get<ExamDto[]>(`${url}/type?siteIds[]=${siteId}`);
  }
}
