import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";

interface DateInputProps {
  value: string;
  onChange: (value: string) => void;
  error?: boolean;
  helperText?: string;
}

const DateInput: React.FC<DateInputProps> = ({
  value,
  onChange,
  error,
  helperText,
}) => {
  const [inputValue, setInputValue] = useState(value);

  const handleInputChange = (value: string) => {
    const numbersOnly = value.replace(/\D/g, "");

    let day = numbersOnly.slice(0, 2);
    let month = numbersOnly.slice(2, 4);
    let year = numbersOnly.slice(4, 8);

    // Get the current year
    const currentYear = new Date().getFullYear();

    // Convert day, month, and year to numbers to perform comparisons
    const dayNum = parseInt(day, 10);
    const monthNum = parseInt(month, 10);
    const yearNum = parseInt(year, 10);

    // Validate and correct day, month, and year values
    day = dayNum > 31 ? "31" : day;
    month = monthNum > 12 ? "12" : month;
    year = yearNum > currentYear ? currentYear.toString() : year;

    // Construct the formatted string with potential adjustments
    const formatted = [day, month, year].filter(Boolean).join("/");

    return formatted;
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = handleInputChange(event.target.value);
    setInputValue(formattedValue);
    onChange(formattedValue);
  };

  return (
    <TextField
      label="Date de naissance (JJ/MM/AAAA)"
      variant="outlined"
      value={inputValue}
      onChange={handleChange}
      error={error}
      helperText={helperText}
      placeholder="JJ/MM/AAAA"
      fullWidth
    />
  );
};

export default DateInput;
