import {
  AppointmentRequestDto,
  CreateAppointmentRequestDto,
} from "../interfaces/api-interfaces";
import { APiBaseService } from "./api-base.service";

export class AppointmentRequestService extends APiBaseService {
  constructor() {
    super();
    this.baseUrl = `${process.env.REACT_APP_API_URL}/appointment-request`;
  }

  create(dto: CreateAppointmentRequestDto): Promise<AppointmentRequestDto> {
    return this.postForm<AppointmentRequestDto>(this.baseUrl, dto);
  }
}
