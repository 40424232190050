import { APiBaseService } from "./api-base.service";
import { FeatureCollection } from "geojson";

export class BanService extends APiBaseService {
  constructor() {
    super();
    this.baseUrl =
      process.env.REACT_APP_ADDRESS_API_URL ||
      "https://address.ether.healthcare";
  }

  public async search(q: string): Promise<{ place: string; id: string }[]> {
    const result = await this.get<FeatureCollection>(
      `${this.baseUrl}/search?type=municipality&q=${q.replace(
        /\s/g,
        "-"
      )}&limit=4`
    );

    const values: { id: string; place: string }[] = [];
    result.features.forEach((f) => {
      values.push({
        place:
          f.properties?.postcode.substring(0, 2) + ", " + f.properties?.city,
        id: f.properties?.id,
      });
    });

    return values;
  }
}
