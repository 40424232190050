// export function isValidDate(d: Date) {
//   return d instanceof Date && !isNaN(d as any);
// }
export function isValidDate(day: number, month: number, year: number): boolean {
  const currentYear = new Date().getFullYear();

  if (year < 1 || year > currentYear) {
    return false;
  }

  if (month < 1 || month > 12) {
    return false;
  }

  if (day < 1) {
    return false;
  }

  const maxDayInMonth = new Date(year, month, 0).getDate();

  if (day > maxDayInMonth) {
    return false;
  }

  return true;
}
