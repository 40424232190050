import {
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { CreateAnswerDto, QuestionDto } from "../interfaces/api-interfaces";

type QuestionsProps = {
  question: QuestionDto;
  onAnswerChange?: (answer: CreateAnswerDto) => void;
  defaultValue?: string | null;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Questions({
  question,
  onAnswerChange,
  defaultValue: defaultAnswer,
}: QuestionsProps) {
  const [isSecondaryQuestionVisible, setIsSecondaryQuestionVisible] =
    useState(false);
  const [secondaryQuestionAnswers, setSecondaryQuestionAnswers] = useState<
    CreateAnswerDto[]
  >([]);

  const { text, type, choices, childQuestions, askSecondaryIf } = question;

  const formik = useFormik<{
    answer?: string;
    choice?: boolean;
    date?: Date;
  }>({
    initialValues: {},
    onSubmit: (values) => {
      console.log({ values });
    },
  });

  useEffect(() => {
    let answer: any = formik.values.answer;
    if (type === "DATE") {
      if (!answer) {
        return;
      }
      const date = (answer as any).$d;
      answer = moment(date).format("DD-MM-YYYY");
      console.log({ answerAfter: answer });
    }

    if (childQuestions?.length !== 0 && answer === askSecondaryIf) {
      setIsSecondaryQuestionVisible(true);
    } else {
      setIsSecondaryQuestionVisible(false);
    }
    if (onAnswerChange) {
      const obj: CreateAnswerDto = {
        questionId: question.id,
        text: answer?.toString(),
        secondaryAnswers: secondaryQuestionAnswers.map((answer) => ({
          questionId: answer.questionId,
          text: answer.text || "",
        })),
      };

      onAnswerChange(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.answer, secondaryQuestionAnswers]);

  const handleSecondaryAnswerChange = (obj: CreateAnswerDto) => {
    const answers: CreateAnswerDto[] = [...secondaryQuestionAnswers];
    const index = secondaryQuestionAnswers.findIndex(
      (answ) => answ.questionId === obj.questionId
    );
    if (index !== -1) {
      answers[index].text = obj.text;
    } else {
      answers.push(obj);
    }
    setSecondaryQuestionAnswers(answers);
  };

  const _handleChange = (ev: any) => {
    const values = ev.target.value;

    formik.setFieldValue("answer", values.join("|"));
  };
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      style={{
        marginTop: "10px",
        marginBottom: "10px",
        width: "100%",
      }}
    >
      {type === "OPEN" ? (
        <>
          <Grid item xs={12}>
            <Typography variant="body1" component="body">
              {text}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="answer"
              name="answer"
              variant="outlined"
              onChange={formik.handleChange}
            />
          </Grid>
        </>
      ) : type === "CLOSED" ? (
        <>
          <Grid item xs={12}>
            <Typography color="primary" variant="body1">
              {text}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={formik.values.answer}
              name="answer"
              onChange={formik.handleChange}
            >
              <FormControlLabel value="TRUE" control={<Radio />} label="Oui" />
              <FormControlLabel value="FALSE" control={<Radio />} label="Non" />
            </RadioGroup>
            {/* <Radio
              name="answer"
              defaultValue={defaultAnswer || undefined}
              options={[
                { label: "Oui", value: "TRUE" },
                { label: "Non", value: "FALSE" },
              ]}
            /> */}
          </Grid>
        </>
      ) : type === "DATE" ? (
        <>
          <Grid item xs={12}>
            <Typography
              color="primary"
              variant="body1"
              style={{ marginBottom: "5px" }}
            >
              {text}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputFormat="DD/MM/YYYY"
                value={formik.values.answer}
                onChange={(value) => formik.setFieldValue("answer", value)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
        </>
      ) : type === "CHOICES" ? (
        <>
          <Grid item xs={12}>
            <Typography color="primary" variant="body1">
              {text}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginBottom: "10px",
            }}
          >
            <Select
              name="answer"
              variant="outlined"
              value={formik.values.answer}
              onChange={formik.handleChange}
              fullWidth
            >
              {choices.map((c) => (
                <MenuItem key={c.id} value={c.text}>
                  {c.text}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </>
      ) : type === "MULTICHOICES" ? (
        <>
          <Grid item xs={12}>
            <Typography color="primary" variant="body1">
              {text}
            </Typography>
          </Grid>
          <Typography style={{ color: "grey", fontSize: "12px" }}>
            Selectionner le ou les champs concernés
          </Typography>
          <Grid
            item
            xs={12}
            style={{
              marginBottom: "10px",
            }}
          >
            <Select
              name="answer"
              variant="outlined"
              value={formik.values.answer?.split("|") || []}
              onChange={_handleChange}
              multiple
              placeholder=""
              fullWidth
            >
              {choices.map((c) => (
                <MenuItem key={c.id} value={c.text}>
                  {c.text}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </>
      ) : (
        ""
      )}
      {isSecondaryQuestionVisible &&
        question.childQuestions?.map((q) => (
          <Grid item xs={12} style={{ marginBottom: "10px" }} key={q.id}>
            <Questions
              question={q}
              onAnswerChange={handleSecondaryAnswerChange}
            />
          </Grid>
        ))}
    </Grid>
  );
}
