import CloseIcon from "@mui/icons-material/Close";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import InfoIcon from "@mui/icons-material/Info";
import IMAGES from "../../../images/Images";
import MedicationIcon from "@mui/icons-material/Medication";
import PersonIcon from "@mui/icons-material/Person";
import SendIcon from "@mui/icons-material/Send";
import {
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { FileInput } from "../../../components/FileInput/FileInput";
import { RppsAutocompleteByNameInput } from "../../../components/inputs/RppsAutocompleteByNameInput";
import Questions from "../../../components/Questions";
import {
  CreateAnswerDto,
  CreateAppointmentRequestDto,
  RppsDto,
  Site,
} from "../../../interfaces/api-interfaces";
import { AppointmentRequestService } from "../../../services/appointment-request.service";
import { ExamService } from "../../../services/exam.service";
import { QuestionService } from "../../../services/question.service";
import { SiteService } from "../../../services/sites.service";
import {
  SinemDoctorFormSchema,
  SinemDoctorFormValues,
} from "../../../shared/schemas/sinem-apointment-form.schema";
import AppointmentSuccess from "./AppointmentSuccess";
import DateInput from "../../../components/inputs/DateInput";

const SinemDoctorForm = () => {
  const siteService = useRef(new SiteService()).current;
  const examService = useRef(new ExamService()).current;
  const questionService = useRef(new QuestionService()).current;
  const [files, setFiles] = useState<File[]>([]);
  const [specificDate, setSpecificDate] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const answersMap = useRef(new Map<number, CreateAnswerDto>()).current;
  const handleSubmit = async (values: SinemDoctorFormValues) => {
    if (values.referringDoctor === null) {
      return;
    }

    const dto: CreateAppointmentRequestDto = {
      examId: parseInt(values.exam + ""),
      patientFirstName: values.patientFirstName,
      patientlastName: values.patientLastName,
      patientBirthDate: values.patientBirthDate.replace(/\//g, "-"),
      patientSex: values.patientSex === "male" ? "M" : "F",
      patientPhone: values.patientPhone,
      patientWeight: values.patientWeight,
      siteId: parseInt(values.site + ""),
      submittedBy: "doctor",
      answers: Array.from(answersMap.values()),
      patientAddress: values.patientAddress,
      patientMobility: values.patientMobility,
      patientOrigin: values.patientOrigin,
      resultViaEmail: true,
      patientHeight: values.patientHeight,
      preferredDate:
        values.preferedDate === "specific" ? specificDate : values.preferedDate,
      referringDoctorEmail: values.doctorEmail,
      referringDoctorPhone: values.doctorPhone,
      referringDoctor: values.referringDoctor,
      files,
      comments: values.comments,
    };

    const appointmentRequestService = new AppointmentRequestService();
    try {
      setIsSending(true);
      if (errorMessage) {
        setErrorMessage("");
      }
      await appointmentRequestService.create(dto);
      setIsSuccess(true);
    } catch (err) {
      setErrorMessage(
        "Il y a eu un problème, merci de réessayer plus tard ou de nous contacter.",
      );
    } finally {
      setIsSending(false);
    }
  };
  const formik = useFormik<SinemDoctorFormValues>({
    initialValues: {
      patientSex: "",
      patientFirstName: "",
      patientLastName: "",
      patientPhone: "",
      patientBirthDate: "",
      examType: "",
      preferedDate: "",
      doctorRpps: "",
      comments: "",
      exam: -1,
      patientHeight: 0,
      patientWeight: 0,
      site: -1,
      patientAddress: "",
      referringDoctor: null,
      doctorEmail: "",
      doctorPhone: "",
      resultViaEmail: true,
      patientMobility: "",
      patientOrigin: "E",
    },
    validationSchema: SinemDoctorFormSchema,
    onSubmit: handleSubmit,
    validateOnChange: false,
  });

  const clientId = 5;
  const { data: sites } = useQuery<Site[]>(
    "sites",
    () => siteService.find(clientId),
    {
      initialData: [],
    },
  );

  const { data: examTypes } = useQuery({
    queryKey: ["examTypes", formik.values.site],
    queryFn: ({ queryKey }) => examService.findExamTypes(queryKey[1] as number),
    initialData: [],
  });

  const { data: exams } = useQuery({
    queryKey: ["exams", formik.values.site, formik.values.examType],
    queryFn: ({ queryKey }) => {
      const siteId = isNaN(queryKey[1] as number)
        ? undefined
        : (queryKey[1] as number);
      const examTypeId = isNaN(queryKey[2] as number)
        ? undefined
        : (queryKey[2] as number);

      return examService.findExams(siteId, examTypeId);
    },
    initialData: { totalCounts: 0, datas: [] },
  });

  const { data: questions } = useQuery({
    queryKey: ["questions", formik.values.exam],
    queryFn: ({ queryKey }) => questionService.find(queryKey[1] as number),
    initialData: [],
    enabled: !!formik.values.exam,
  });

  const siteOptions = sites?.map((site) => ({
    label: site.label,
    value: site.id,
  })) as { label: string; value: string | number }[];

  const examTypeOptions = examTypes?.map((type) => ({
    label: type.label,
    value: type.id.toString(),
  })) as { label: string; value: string }[];

  const examOptions = exams?.datas?.map((ex) => ({
    label: ex.label,
    value: ex.id.toString(),
  })) as { label: string; value: string }[];

  const handleRppsChange = (rpps: RppsDto | null) => {
    const { setFieldValue } = formik;
    setFieldValue("referringDoctor", {
      doctorName: rpps?.firstName + " " + rpps?.lastName,
      rpps: rpps?.rppsNumber,
      id: rpps?.id,
      skill: rpps?.skillLabel,
    });

    //TODO remove this
    setFieldValue("doctorRpps", rpps?.rppsNumber);
  };

  const handleFileChange = (file: File) => {
    setFiles([...files, file]);
  };
  const handleFileDelete = (file: File) => {
    setFiles(files.filter((f) => f !== file));
  };

  const handleAnswerChange = (answerDto: CreateAnswerDto) => {
    const key = answerDto.questionId;
    answersMap.set(key, answerDto);
  };

  const onSubmit = async () => {
    const errors = await formik.validateForm();
    if (Object.values(errors).length !== 0) {
      setErrorMessage(
        "Certains champs obligatoires ne sont pas renseignés ou sont incorrects, merci de vérifier et soumettre à nouveau",
      );
    }
    await formik.submitForm();
  };

  const isSpecificDateVisible = () => {
    const v = formik.values.preferedDate;
    return v !== "Urgence" && v !== "-15 jours" && v !== "+15 jours";
  };
  useEffect(() => {
    answersMap.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.exam]);

  const instructions = useMemo(() => {
    const ex = exams?.datas.find(
      (ex) => ex.id === parseInt(formik.values.exam.toString()),
    );

    return ex?.instructionsList.sort((i1, i2) =>
      i1.label > i2.label ? 1 : -1,
    );
  }, [formik.values.exam, exams?.datas]);

  const confirmationMessage = (
    <div>
      <p>
        Demande envoyée avec succès, vous allez recevoir un email de
        confirmation. Si vous ne le trouvez pas, pensez à vérifier vos SPAMS.
        <br />
        <br />
        Si vous utilisez une messagerie sécurisée avec votre établissement, il
        se peut que vous ne receviez pas la confirmation, rassurez-vous, votre
        demande de rendez-vous est bien transmise
        <br />
        <br />
        Vous pouvez ajouter l'adresse email suivante à vos contacts pour vous
        assurer de la bonne réception de ces emails dans le futur:
        <br />
        <br />
        <b>prescription-web@ether.healthcare</b>
        <br />
        <br />
        ou demander à votre administrateur d'autoriser le domaine{" "}
        <b>ether.healthcare</b>
      </p>
    </div>
  );

  return isSuccess ? (
    <AppointmentSuccess message={confirmationMessage} />
  ) : (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
      sx={{
        padding: "10px",
        backgroundColor: "#00509E10",
      }}
    >
      <Grid
        container
        xs={12}
        sx={{ height: "100%", padding: "20px", overflow: "auto" }}
      >
        <Grid item xs={12} sx={{ marginBottom: "5px" }}>
          <Typography
            variant="body1"
            component="h6"
            sx={{ color: "orange", fontWeight: "500" }}
          >
            <InfoIcon sx={{ color: "orange", marginRight: "2px" }} />
            Les champs marqués d'une * sont obligatoires
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider>
            <ContactEmergencyIcon
              sx={{ marginBottom: "-10px", color: "#00509E" }}
            />
            <Typography variant="h6" component="h6" sx={{ color: "#00509E" }}>
              Docteur
            </Typography>
          </Divider>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            Médecin qui demande l'examen (Prénom puis Nom)*
          </Typography>
          <Grid item xs={12}>
            <Typography style={{ color: "grey", fontSize: "12px" }}>
              Merci de ne sélectionner qu'un seul résultat (vous pouvez affiner
              la recherche avec le № départment).
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <RppsAutocompleteByNameInput onChange={handleRppsChange} />
            <Typography className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
              {formik.errors.doctorRpps}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            Adresse Email du médecin - ou du secrétariat*
          </Typography>
          <TextField
            name="doctorEmail"
            label="Adresse Email du docteur"
            variant="outlined"
            fullWidth
            value={formik.values.doctorEmail}
            onChange={formik.handleChange}
            helperText={formik.errors.doctorEmail || ""}
            error={!!formik.errors.doctorEmail}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            Téléphone du médecin - ou du secrétariat*
          </Typography>
          <TextField
            name="doctorPhone"
            label="Téléphone du docteur"
            variant="outlined"
            fullWidth
            value={formik.values.doctorPhone}
            onChange={formik.handleChange}
            helperText={formik.errors.doctorPhone || ""}
            error={!!formik.errors.doctorPhone}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider>
            <PersonIcon sx={{ marginBottom: "-10px", color: "#00509E" }} />
            <Typography variant="h6" component="h6" sx={{ color: "#00509E" }}>
              Patient
            </Typography>
          </Divider>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            Civilité*
          </Typography>
          <RadioGroup
            aria-labelledby="genderSelectionRadioButtons"
            name="patientSex"
            value={formik.values.patientSex}
            onChange={formik.handleChange}
            row
          >
            <FormControlLabel
              value="female"
              control={<Radio />}
              label="Mme/Mlle"
            />
            <FormControlLabel value="male" control={<Radio />} label="Mr" />
          </RadioGroup>
          <Typography className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
            {formik.errors.patientSex}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            Prénom*
          </Typography>
          <TextField
            name="patientFirstName"
            label="Prénom du patient"
            variant="outlined"
            fullWidth
            value={formik.values.patientFirstName}
            onChange={formik.handleChange}
            helperText={formik.errors.patientFirstName || ""}
            error={!!formik.errors.patientFirstName}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            Nom de famille*
          </Typography>
          <TextField
            name="patientLastName"
            label="Nom de famille du patient"
            variant="outlined"
            fullWidth
            value={formik.values.patientLastName}
            onChange={formik.handleChange}
            helperText={formik.errors.patientLastName || ""}
            error={!!formik.errors.patientLastName}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            Téléphone
          </Typography>
          <TextField
            name="patientPhone"
            label="Mobile de préférence"
            variant="outlined"
            fullWidth
            value={formik.values.patientPhone}
            onChange={formik.handleChange}
            helperText={formik.errors.patientPhone || ""}
            error={!!formik.errors.patientPhone}
          />
          <Typography variant="h6" component="h6">
            <span
              style={{
                color: "orange",
                fontSize: "0.8rem",
              }}
            >
              Afin de faciliter le travail de nos secrétaires, il est conseillé
              de renseigner le numéro de téléphone du patient.{" "}
            </span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            Adresse*
          </Typography>
          <TextField
            name="patientAddress"
            label="Adresse"
            variant="outlined"
            value={formik.values.patientAddress}
            onChange={formik.handleChange}
            fullWidth
            helperText={formik.errors.patientAddress || ""}
            error={!!formik.errors.patientAddress}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            Date de naissance*
          </Typography>
          <DateInput
            value={formik.values.patientBirthDate}
            onChange={(value) =>
              formik.setFieldValue("patientBirthDate", value)
            }
            error={!!formik.errors.patientBirthDate}
            helperText={formik.errors.patientBirthDate}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            Résultat par email / en ligne?
          </Typography>
          <RadioGroup
            aria-labelledby="resultViaEmailSelectionRadioButtons"
            name="resultViaEmail"
            value={formik.values.resultViaEmail}
            onChange={formik.handleChange}
            row
          >
            <FormControlLabel value={true} control={<Radio />} label="Oui" />
            <FormControlLabel value={false} control={<Radio />} label="Non" />
          </RadioGroup>
        </Grid>

        <Grid item xs={12} sx={{ marginTop: "5px" }}>
          <Divider>
            <MedicationIcon sx={{ marginBottom: "-10px", color: "#00509E" }} />
            <Typography variant="h6" component="h6" sx={{ color: "#00509E" }}>
              Examen
            </Typography>
          </Divider>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            Choisissez un de nos centres d'imagerie*
          </Typography>
          <Select
            name="site"
            value={formik.values.site}
            fullWidth
            onChange={formik.handleChange}
            error={!!formik.errors.site}
          >
            {siteOptions.map((site) => (
              <MenuItem key={site.value} value={site.value}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span
                    style={{
                      color: "#3f3f3f",
                      fontSize: "1.2rem",
                      marginRight: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    {site.value === 5 ? "Saintes" : "Poitiers"}
                  </span>
                  <img
                    src={
                      site.value === 5
                        ? IMAGES.logoSaintes
                        : IMAGES.logoPoitiers
                    }
                    width="100px"
                    alt="logo-du-centre"
                  />
                </div>
              </MenuItem>
            ))}
          </Select>
          <Typography style={{ color: "red" }}>{formik.errors.site}</Typography>
        </Grid>
        {formik.values.site && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6">
                Type d'examen*
              </Typography>
              <Select
                name="examType"
                value={formik.values.examType}
                fullWidth
                onChange={formik.handleChange}
              >
                {examTypeOptions.map((examType) => (
                  <MenuItem key={examType.value} value={examType.value}>
                    {examType.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </>
        )}
        {formik.values.examType && (
          <Grid item xs={12}>
            <Typography variant="h6" component="h6">
              Examen*
            </Typography>
            <Select
              name="exam"
              value={formik.values.exam}
              fullWidth
              onChange={formik.handleChange}
              error={!!formik.errors.examType}
            >
              {examOptions.map((exam) => (
                <MenuItem key={exam.value} value={exam.value}>
                  {exam.label}
                </MenuItem>
              ))}
            </Select>
            <Typography className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
              {formik.errors.examType}
            </Typography>
          </Grid>
        )}
        {formik.values.exam && (
          <Grid item xs={12}>
            <Typography variant="h6" component="h6">
              Période de rendez-vous souhaitée*
            </Typography>

            <Select
              name="preferedDate"
              value={formik.values.preferedDate}
              fullWidth
              onChange={formik.handleChange}
              error={!!formik.errors.preferedDate}
            >
              <MenuItem value={"Urgence"}>Urgence</MenuItem>
              <MenuItem value={"-15 jours"}>-15 jours</MenuItem>
              <MenuItem value={"+15 jours"}>+15 jours</MenuItem>
              <MenuItem value={"specific"}>
                Spécifique{" "}
                <span
                  style={{
                    color: "gray",
                    fontSize: "0.8rem",
                    marginLeft: "5px",
                  }}
                >
                  (précisez)
                </span>
              </MenuItem>
            </Select>
            <Typography className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
              {formik.errors.preferedDate}
            </Typography>
          </Grid>
        )}
        {isSpecificDateVisible() && (
          <Grid item xs={12} sx={{ marginTop: "10px" }}>
            <TextField
              name="preferedDate"
              label="Contraintes / Préférences"
              placeholder="ex: Seulement les lundis"
              variant="outlined"
              value={specificDate}
              onChange={(ev) => setSpecificDate(ev.target.value)}
              multiline
              rows={4}
              fullWidth
              helperText={formik.errors.preferedDate}
            />
          </Grid>
        )}
        {formik.values.exam && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6">
                Taille*{" "}
                <span style={{ color: "gray", fontSize: "0.8em" }}>
                  (en cm)
                </span>
              </Typography>
              <TextField
                type="number"
                name="patientHeight"
                label="Taille"
                variant="outlined"
                fullWidth
                value={
                  formik.values.patientHeight
                    ? parseInt(formik.values.patientHeight.toString())
                    : ""
                }
                onChange={formik.handleChange}
                error={!!formik.errors.patientHeight}
                helperText={formik.errors.patientHeight}
                InputProps={{
                  inputProps: {
                    type: "number",
                  },
                  startAdornment: (
                    <InputAdornment position="start">cm</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6">
                Poids*{" "}
                <span style={{ color: "gray", fontSize: "0.8em" }}>
                  (en kg)
                </span>
              </Typography>
              <TextField
                type="number"
                name="patientWeight"
                label="Poids"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">kg</InputAdornment>
                  ),
                }}
                error={!!formik.errors.patientWeight}
                helperText={formik.errors.patientWeight}
                value={
                  formik.values.patientWeight
                    ? parseInt(formik.values.patientWeight.toString())
                    : ""
                }
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6">
                Origine du patient*
              </Typography>
              <RadioGroup
                aria-labelledby="originSelectionRadioButtons"
                name="patientOrigin"
                value={formik.values.patientOrigin}
                onChange={formik.handleChange}
                row
              >
                <FormControlLabel
                  value="E"
                  control={<Radio />}
                  label="Externe"
                />
                <FormControlLabel
                  value="H"
                  control={<Radio />}
                  label="Hospitalisé"
                />
              </RadioGroup>
              <Typography className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
                {formik.errors.patientOrigin}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6">
                Patient valide?
              </Typography>
              <Select
                name="patientMobility"
                value={formik.values.patientMobility}
                fullWidth
                onChange={formik.handleChange}
                error={!!formik.errors.patientMobility}
              >
                <MenuItem value="Ne sait pas">Ne sait pas</MenuItem>
                <MenuItem value="Oui">Oui</MenuItem>
                <MenuItem value="Fauteuil roulant">Fauteuil roulant</MenuItem>
                <MenuItem value="Brancard">Brancard</MenuItem>
              </Select>
              <Typography className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
                {formik.errors.patientMobility}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {questions?.map((_question) => (
                <Questions
                  question={_question}
                  onAnswerChange={handleAnswerChange}
                />
              ))}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6">
                Informations Médicales
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="comments"
                label="Informations / Motifs"
                variant="outlined"
                multiline
                minRows={4}
                fullWidth
                value={formik.values.comments}
                onChange={formik.handleChange}
                error={!!formik.errors.comments}
                helperText={!!formik.errors.comments}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "5px" }}>
              <Divider>
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{ color: "#00509E" }}
                >
                  Documents
                </Typography>
              </Divider>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6">
                Joindre une ordonnance / courrier / prise de sang...
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FileInput
                files={files}
                onChange={handleFileChange}
                onDelete={handleFileDelete}
              />
            </Grid>
            <Grid item xs={12}></Grid>

            <Grid item xs={12} sx={{ marginTop: "5px" }}>
              <Divider>
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{ color: "#00509E" }}
                >
                  Instructions à suivre
                </Typography>
              </Divider>
            </Grid>
            <Grid container sx={{ padding: "20px" }}>
              <Grid
                item
                xs={10}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {instructions?.map((_instruction, index) => (
                  <Typography
                    key={index}
                    variant="h6"
                    component="p"
                    sx={{
                      ":first-letter": {
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "1.2em",
                      },
                    }}
                  >
                    {_instruction.label}
                  </Typography>
                ))}
              </Grid>
            </Grid>
            {errorMessage && (
              <Grid
                item
                xs={12}
                sx={{
                  marginTop: "15px",
                  backgroundColor: "#fce5e8",
                  padding: "8px",
                  position: "relative",
                }}
              >
                <CloseIcon
                  style={{
                    float: "right",
                    right: 4,
                    color: "red",
                    fontSize: "18px",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  onClick={() => setErrorMessage("")}
                />
                <Typography
                  variant="body1"
                  style={{ color: "red" }}
                  fontSize={14}
                >
                  {errorMessage}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sx={{ marginTop: "15px" }}>
              <Button
                onClick={onSubmit}
                variant="contained"
                endIcon={
                  isSending ? (
                    <CircularProgress size={20} style={{ color: "darkgray" }} />
                  ) : (
                    <SendIcon />
                  )
                }
                fullWidth
                disabled={isSending}
              >
                {isSending ? "Patientez" : "Confirmer"}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default SinemDoctorForm;
