import { serialize } from "../utils";

export class APiBaseService {
  protected baseUrl = process.env.REACT_APP_API_URL as string;

  public async get<T>(
    url: string,
    query?: { [key: string]: string | number },
    headers?: { [key: string]: string }
  ): Promise<T> {
    if (query) {
      if (!url.includes("?")) {
        url += "?";
      } else {
        url += "&";
      }
      url += serialize(query);
    }
    const response = await fetch(url, {
      method: "GET",
      ...(!!headers && { headers: new Headers(headers) }),
    });

    return response.json();
  }
  public async post<T>(url: string, body?: any): Promise<T> {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (response.status >= 400) {
      const text = await response.text();
      throw Error(text);
    }

    return response.json();
  }
  public async postForm<T>(url: string, body?: any): Promise<T> {
    let formData = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      if (key !== "files") {
        if (value instanceof Date) {
          formData.append(key, value.toISOString());
        } else if (typeof value === "object" && value !== null) {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value as string);
        }
      } else {
        (value as File[]).forEach((f) => {
          console.log({ f });
          formData.append("files", f);
        });
      }
    });
    // return {} as T;
    const response = await fetch(url, {
      method: "POST",
      body: formData,
    });
    if (response.status >= 400) {
      const text = await response.text();
      throw Error(text);
    }

    return response.json();
  }
  public async put<T>(url: string, body?: any): Promise<T> {
    const response = await fetch(url, {
      method: "PUT",
      body,
    });

    return response.json();
  }
  public async patch<T>(url: string, body?: any): Promise<T> {
    const response = await fetch(url, {
      method: "PATCH",
      body,
    });

    return response.json();
  }
  public async delete<T>(url: string): Promise<T> {
    const response = await fetch(url, {
      method: "DELETE",
    });

    return response.json();
  }
}
